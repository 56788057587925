<template>
  <div class="page-box page-about  bg-white">
    <ban-view :banStyle="banStyle"></ban-view>

    <div class="content bxb-con bxb-p70">
      <p>
        百色市远兰小额贷款有限责任公司，致力于以科技推动包括金融服务业在内的全球现代服务业的数字化升级，携手合作伙伴为消费者和小微企业提供普惠、绿色、可持续的服务，为世界带来微小而美好的改变。
      </p>
      <p>
        公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术、管理方法及企业经验与国内企业的具体实际相结合，为企业供给全方位的解决方案，帮忙企业提高管理水平和生产本事，使企业在激烈的市场竞争中始终坚持竞争力，实现企业快速、稳定地发展。
      </p>
      <p>
        最好的产品、最好的服务、最好的信誉，百色市远兰小额贷款有限责任公司也愿意成为您最可信赖的长期合作伙伴。
      </p>
      <p>我们的价值观：</p>
      <p>客户第一 员工第二 股东第三</p>
      <p>今天最好的表现是明天最低的要求</p>
      <p>因为信任 所以简单 认真生活 快乐工作</p>
      <p>
        <img src="https://ylxd.xcongzb.com/img/news08.jpg" alt="" />
      </p>
    </div>
  </div>
</template>

<script>
import BanView from "components/content/banView/BanView";
export default {
  name: "About",
  components: {
    BanView
  },
  data() {
    return {
      banStyle: {
        height: "300px",
        backgroundImage: 'url("https://ylxd.xcongzb.com/img/ban-about.jpg")'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  line-height: 2.4;
  img {
    margin: 20px auto;
  }
}
</style>
